*{
  margin: 0;
  padding: 0;
  font-family:'Nunito Sans', sans-serif;;
}

/* LOGIN PAGE */

.login{
  background-color: black;
  border: none;
  border-radius: 25px;
  margin-top: 79px;
  box-shadow:  1px 1px 20px 11px #FFBE73;
}

.login img{
  height: 330px;
  width: 427px;
  padding: 23px;
}

.login input {
  background-color: black;
  color: white;
}

.login input:focus {
  background-color: black;
  color: white;
}

.login input::placeholder {
  color: white;
}

.login button {
  background-color: white;
  color: black;
}

.login button:disabled {
  background-color: white;
  color: black;
}

.btn-primary{
  background-color: black;
  color: white;
  border: none;
  margin-bottom: 15px;
  font-size: 20px;
  cursor: pointer;
}

.btn-primary:hover{
  background-color: grey !important;
}  

.btn:disabled{
  background-color: black;
  color: white;
}


/* NAV BAR */

.style-nav-logo{
  height: 50px;
  width: 50px;
}

.nav-link:hover{
  color:grey;
}

.navbar-nav .nav-link.active{
  color: #FFBE73;
}

.navbar-toggler{
  color: #FFBE73;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.style-nav{
  border: none;
  background-color: black;
  padding: 5px 10px 5px 10px;
}

.logout{
  border: none;
  background: black;
}

.style-nav-link{
  font-size: 15px;
  color: white;
  font-weight: bold;
}

.style-name{
  color:#FFBE73;
  font-weight: bold;
  font-size: 12px;
  margin:0;
  text-transform: uppercase;

}

/* MODAL */
.style-modal-header{
 background-color: grey;
 color: white;
}

.text-modal{
  font-weight: bold;
}


/* INVENTORY */

.style-title-page{
  font-weight: bold;
  font-size: 30px;
}

.btn-table{
  background-color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
}

.btn.btn-table:hover{
  background-color:grey;
  color: white;
}

table .row-click {
  cursor: pointer;
}

/* CASHIER */

.cashier-select {
  font-size: 15px;
}

.input-title {
  font-weight: bold;
}

.title-bar{
  background-color: grey;
  color: white;
  font-weight: bold;
  text-align: center;
}

.cashier-form .invalid-input {
  border-color: red;
  box-shadow: none;
}


/* GLOBAL */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.text-ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
}


/* SALES PAGE */

.style-icon-sale{
  color: grey;
  font-size: 30px;
  margin-right: 22px;
  margin-left: 6px;
}

.style-cards{
  display: flex;
  width: max-content;
  align-items: center;
  border-radius: 19px;
  box-shadow:  1px 1px 8px 2px grey;
  padding: 10px 9px;
}

.style-cards p {
  margin-bottom: 0;
}

.bold{
  font-weight: bold;
}
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input{
  border-radius: 5px;
  padding: 3px 5px;
  border-color: grey;
  width: 100%;
}
.react-datepicker .react-datepicker__day--in-range{
  background-color: #dbb82d;
}
/* CLOSED CASHIER */

.cards-closed{
  width: max-content;
  align-items: center;
  border-radius: 19px;
  box-shadow:  1px 1px 8px 2px grey;
  padding: 10px 9px;
}

.cards-closed p{
  margin-bottom: 0;
}

.cards-closed p span{
  font-weight: normal;
}

.style-buttom-closed{
  font-size: 15px;
}

.button-closed{
  padding: 0px 37px;
}

.yellow-color{
  color:#dbb82d;
}

.modal-content .modal-user-name {
  box-shadow: 1px 1px 8px 5px white;
  border-radius: 20px;
  padding: 7px 12px;
  color: #6c540e;
}

.error-msg {
  color: red;
}

.invoice ul {
  padding-top: 80px;
}

.invoice li span {
  font-weight: bold;
  display: block;
}

.take-photo{
  border-radius: 50%;
  background-color: #dbb82d;
  width: fit-content;
  padding: 3%;
}

.take-photo span{
  font-size: 10px;
}

.expense-preview {
  width: 59px;
  height: 59px;
}

.invoice-img {
  max-width: 100%
}

.new-expenses-btn {
  width: fit-content;
}

@media screen {
	.invoice {
		visibility: hidden;
	}
}

/* MOBILE STYLES */

@media (max-width: 700px){
  .login img{
    height: 300px;
    width: 330px;
  }

  .t-responsive-large {
    width: 660px;
  }

  .t-responsive-medium {
    width: 580px;
  }

  .cashier-form {
    font-size: 12px;
  }

  .cashier-form .btn-table {
    padding: 0 7px;
  }
  .cashier-form .btn-table i {
    font-size: 12px;
  }

  .cashier-form input {
    font-size: 12px;
  }
  .cashier-select {
    font-size: 12px;
  }
}
